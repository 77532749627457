import React, { useMemo } from "react"
import Logo from "../../../../../assets/icons/logo.png"

import { FollowUpQuestions, ChatFooter } from "../index"
import { FOLLOW_UP_QUESTIONS } from "../../constants/FollowUpQuestions.constant"

import "./style.scss"

export const ChatWelcomeState = ({ onSubmit }) => {
  const [selectedQestion, setSelectedQuestion] = React.useState(null)

  const [firstQuestion, secondQuestion, thirdQuestion] = useMemo(
    () => getUniqueRandomItems(FOLLOW_UP_QUESTIONS, 3),
    []
  )

  const handleFollowUpQuestionClick = question => {
    setSelectedQuestion(question)
  }

  return (
    <div className="ChatWelcomeStateWrapper">
      <div className="ChatWelcomeState">
        <div className="ChatWelcomeStateLogo">
          <img src={Logo} alt="Logo" className="img-fluid" />
        </div>
        <div className="ChatWelcomeStateTitle">
          Hi, I’m PRC Macro Chat. What do you want to know?
        </div>
        <div className="ChatWelcomeStateFollowUpQuestions">
          <FollowUpQuestions
            selectedQestion={selectedQestion}
            firstQuestion={firstQuestion}
            secondQuestion={secondQuestion}
            thirdQuestion={thirdQuestion}
            onFollowUpQuestionClick={handleFollowUpQuestionClick}
          />
        </div>
        <div className="ChatWelcomeStateFooter">
          <ChatFooter initValue={selectedQestion} onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}

function getUniqueRandomItems(array, numItems) {
  // Create a Set to store unique random indices
  const uniqueIndices = new Set()

  // Continue until we have the desired number of unique indices
  while (uniqueIndices.size < numItems) {
    const randomIndex = Math.floor(Math.random() * array.length)
    uniqueIndices.add(randomIndex) // Set automatically ensures uniqueness
  }

  // Map unique indices to their corresponding items in the original array
  return Array.from(uniqueIndices).map(index => array[index])
}
