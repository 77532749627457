import React from "react"

import ChatbotIcon from "../../../../../assets/img/chatbotIcon.png"
import RightArrowIcon from "../../../../../assets/img/rightArrowIcon.png"

import "./style.scss"

export const ChatContinueConversationState = ({ onOpenChat }) => {
  return (
    <div className="ChatContinueConversationStateWrapper">
      <div className="ChatContinueConversationState" onClick={onOpenChat}>
        <div>
          <img src={ChatbotIcon} alt="Chat" />
        </div>
        <div className="ChatContinueConversationStateTitle">PRC Macro Chat</div>
        <div className="ChatContinueConversationStateText">|</div>
        <div className="ChatContinueConversationStateText">
          Have further questions? Start a conversation
        </div>
        <div>
          <img src={RightArrowIcon} className="arrowIcon" alt="Right Arrow" />
        </div>
      </div>
    </div>
  )
}
