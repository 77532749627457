import React, { useMemo } from "react"
import { ChatContinueConversationState, ChatWelcomeState } from "./components"

import "./style.scss"

const ChatWidget = ({ isExistActiveChat }) => {
  const WidgetContent = useMemo(() => {
    if (isExistActiveChat) {
      return <ChatContinueConversationState onOpenChat={() => null} />
    }

    return <ChatWelcomeState onSubmit={text => console.log(text)} />
  }, [isExistActiveChat])

  return <div className="ChatWidgetWrapper">{WidgetContent}</div>
}

export default ChatWidget
