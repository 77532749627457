export const FOLLOW_UP_QUESTIONS = [
  "What are the historical origins of Lorem Ipsum?",
  "How is Lorem Ipsum generated, and are there variations of it?",
  "What role does Lorem Ipsum play in modern web design and development?",
  "Can the use of Lorem Ipsum affect design communication?",
  "What are the potential downsides of relying too heavily on Lorem Ipsum?",
  "Are there any notable design projects that successfully used Lorem Ipsum?",
  "How does the use of Lorem Ipsum vary across different design disciplines?",
  "What are some common alternatives to Lorem Ipsum, and when should they be used?",
  "In what ways can the use of Lorem Ipsum impact user experience?",
  "Are there specific guidelines for using Lorem Ipsum in professional design settings?"
]
