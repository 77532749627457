import { all, call, put, takeLatest, select } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"
import { updateAnalyticsAction } from "../Settings/redux"

export const getArticlesState = state => state.VisualHomeReducer.articles

const TYPE = "WEBVISUAL_ARTICLES_"
export const constants = {
  GET_ARTICLES: `${TYPE}GET_ARTICLES`,
  GET_ARTICLES_SUCCESS: `${TYPE}GET_ARTICLES_SUCCESS`,
  RESET_DATA: `${TYPE}RESET_DATA`,
  SEARCH_ARTICLES: `${TYPE}SEARCH_ARTICLES`,
  ADD_FAVOURITE: `${TYPE}ADD_FAVOURITE`,
  DATE_FILETER: `${TYPE}DATE_FILETER`,
  DOWNLOAD_PDF_REQUEST: `${TYPE}DOWNLOAD_PDF_REQUEST`,
  DOWNLOAD_PDF_SUCCESS: `${TYPE}DOWNLOAD_PDF_SUCCESS`
}

const initialState = {
  requesting: false,
  articles: [],
  count: 0,
  pdfData: false,
  actionType: false
}

export const VisualHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DOWNLOAD_PDF_REQUEST:
    case constants.DATE_FILETER:
    case constants.GET_ARTICLES:
    case constants.SEARCH_ARTICLES:
      return {
        ...state,
        requesting: true
      }
    case constants.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.response,
        count: action.count,
        requesting: false
      }

    case constants.DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        requesting: false,
        pdfData: action.response,
        actionType: action.actionType
      }

    case constants.RESET_DATA:
      return {
        ...state,
        ...initialState
      }

    default:
      return state
  }
}

async function getArticlesAPI(offset) {
  const URL = `${BASE_URL}/api/v1/article/?limit=10&offset=${offset}`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getArticles({ offset }) {
  try {
    const response = yield call(getArticlesAPI, offset)
    yield put({
      type: constants.GET_ARTICLES_SUCCESS,
      response: response?.data?.results,
      count: response?.data?.count
    })
  } catch (e) {
    yield put({ type: constants.RESET_DATA })
    toast.error("Failed to get articles")
  }
}

async function searchArticlesAPI(search) {
  const URL = `${BASE_URL}/api/v1/article/filters/?name=${search}`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* searchArticles({ search }) {
  try {
    const response = yield call(searchArticlesAPI, search)
    yield put({
      type: constants.GET_ARTICLES_SUCCESS,
      response: response.data,
      count: response?.data?.length
    })
  } catch (e) {
    yield put({ type: constants.RESET_DATA })
    toast.error("Failed to search articles")
  }
}

async function addFavouriteAPI(id, state) {
  const URL = `${BASE_URL}/api/v1/article/${id}/add_favorite/?is_favorite=${
    state ? "True" : "False"
  }`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* addFavourite({ id, articleDetails, state }) {
  try {
    yield call(addFavouriteAPI, id, state)
    const articles = yield select(getArticlesState)
    const updateArticles = articles.map(article => {
      if (article.id === id) {
        return { ...article, is_favorite: !article.is_favorite }
      }
      return article
    })
    yield put({
      type: constants.GET_ARTICLES_SUCCESS,
      response: updateArticles,
      count: updateArticles.length
    })
    if (articleDetails) {
      toast.success("Article removed from favourites")
    } else {
      toast.success("Article added to favourites")
    }
  } catch (e) {
    yield put({ type: constants.RESET_DATA })
    toast.error("Failed to add favourite")
  }
}

async function dateFilterAPI(params) {
  const URL = `${BASE_URL}/api/v1/article/filters/?${params}`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* dateFilter({ params }) {
  try {
    const response = yield call(dateFilterAPI, params)
    yield put({
      type: constants.GET_ARTICLES_SUCCESS,
      response: response.data
    })
  } catch (e) {
    yield put({ type: constants.RESET_DATA })
    toast.error("Failed to get data for selected date.")
  }
}

async function downloadPdfAPI(data) {
  const URL = `${BASE_URL}/api/v1/article_download/${data.id}/download/`
  const prc_authToken = sessionStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET",
    responseType: "blob"
  }
  return XHR(URL, options)
}

function* downloadPdf({ data, actionType }) {
  try {
    const response = yield call(downloadPdfAPI, data)
    yield put({
      type: constants.DOWNLOAD_PDF_SUCCESS,
      response: response.data,
      actionType
    })
    yield put(updateAnalyticsAction("article", data.id, "downloaded"))
  } catch (e) {
    yield put({ type: constants.RESET_DATA })
    toast.error("Failed to download pdf.")
  }
}

export default all([
  takeLatest(constants.GET_ARTICLES, getArticles),
  takeLatest(constants.SEARCH_ARTICLES, searchArticles),
  takeLatest(constants.ADD_FAVOURITE, addFavourite),
  takeLatest(constants.DATE_FILETER, dateFilter),
  takeLatest(constants.DOWNLOAD_PDF_REQUEST, downloadPdf)
])
