import React, { useEffect } from "react"
import { Input, Button } from "reactstrap"
import sendIcon from "../../../../../assets/img/sendIcon.png"

import "./style.scss"

export const ChatFooter = ({ onSubmit, initValue }) => {
  const [text, setText] = React.useState("")

  useEffect(() => {
    setText(initValue)
  }, [initValue])

  return (
    <div className="ChatFooterWrapper">
      <div className="ChatFooterInputWrapper">
        <Input
          className="ChatFooterInput"
          placeholder="Ask a question"
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <Button className="ChatFooterSendButton" onClick={() => onSubmit(text)}>
          <img className="" width="20px" src={sendIcon} alt="search" />
        </Button>
      </div>
      <div className="ChatFooterText">
        PRC Macro Chat uses AI and can make mistakes. Consider checking results
      </div>
    </div>
  )
}
