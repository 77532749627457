import React, { useState } from "react"

import PageWrapper from "WebVisual/PageWrapper"

const Chat = () => {
  const [search, setSearch] = useState("")

  return (
    <PageWrapper onChange={setSearch} title="Home">
      123123
    </PageWrapper>
  )
}

export default Chat
