import React from "react"
import { NavItem, Nav } from "reactstrap"
import { NavLink, useLocation } from "react-router-dom"
import { ReactComponent as Home } from "../../assets/icons/sidebar/home.svg"
import { ReactComponent as Heart } from "../../assets/icons/sidebar/heart.svg"
import { ReactComponent as Podcast } from "../../assets/icons/sidebar/mic.svg"
import { ReactComponent as Phone } from "../../assets/icons/sidebar/call.svg"
import classNames from "classnames"
import Logo from "../../assets/icons/logo.png"
import "./style.scss"

const Sidebar = ({ isOpen, toggle }) => {
  const pathName = useLocation().pathname

  return (
    <>
      <div className="sidebar-client">
        <div className={classNames("sidebar", { "is-open": isOpen })}>
          <div className="side-menu">
            <Nav vertical className="list-unstyled pb-3">
              <NavItem
                className={
                  pathName === "/user/home" || pathName === "/user/chat"
                    ? "active"
                    : ""
                }
              >
                <NavLink to="/user/home">
                  <span>
                    <Home />
                  </span>
                  HOME
                </NavLink>
              </NavItem>
              <NavItem
                className={pathName === "/user/favourite" ? "active" : ""}
              >
                <NavLink to="/user/favourite">
                  <span>
                    <Heart />
                  </span>
                  FAVOURITE
                </NavLink>
              </NavItem>
              <NavItem className={pathName === "/user/podcast" ? "active" : ""}>
                <NavLink to="/user/podcast">
                  <span>
                    <Podcast />
                  </span>
                  PODCAST
                </NavLink>
              </NavItem>
              <NavItem
                className={pathName === "/user/clientcalls" ? "active" : ""}
              >
                <NavLink to="/user/clientcalls">
                  <span>
                    <Phone />
                  </span>
                  CLIENT CALLS
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="logo-icon d-flex justify-content-center align-items-center w-100">
            <img src={Logo} alt="Logo" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
