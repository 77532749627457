import React from "react"

import "./style.scss"

export const FollowUpQuestions = ({
  onFollowUpQuestionClick,
  selectedQestion,
  firstQuestion,
  secondQuestion,
  thirdQuestion
}) => {
  return (
    <div className="FollowUpQuestionsWrapper">
      <FollowUpQuestion
        question={firstQuestion}
        isActive={selectedQestion === firstQuestion}
        onClick={onFollowUpQuestionClick}
      />
      <FollowUpQuestion
        isActive={selectedQestion === secondQuestion}
        question={secondQuestion}
        onClick={onFollowUpQuestionClick}
      />
      <FollowUpQuestion
        isActive={selectedQestion === thirdQuestion}
        question={thirdQuestion}
        onClick={onFollowUpQuestionClick}
      />
    </div>
  )
}

const FollowUpQuestion = ({ question, onClick, isActive }) => {
  return (
    <div
      className={`FollowUpQuestionWrapper ${isActive ? "isActive" : ""}`}
      onClick={() => onClick(question)}
    >
      <div className="FollowUpQuestionContent">{question}</div>
    </div>
  )
}
